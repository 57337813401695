import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import WalletProvider from './WalletProvider';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Bet from './pages/Bet';
import Lottery from './pages/Lottery'; 
import Roulette from './pages/Roulette'; 
import CoinFlip from './pages/coinflip'; 
import Dice from './pages/Dice'; // Correct import
import Layout from './components/Layout'; 
import { SpeedInsights } from "@vercel/speed-insights/react";

// ---------------------- Theme Configuration ----------------------
const themeConfig = createTheme({
  palette: {
    primary: {
      main: '#FF4500', // Orange Red
    },
    secondary: {
      main: '#FFD700', // Gold
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 1px 3px rgba(0,0,0,0.2)', // shadow[1]
    '0px 3px 6px rgba(0,0,0,0.3)', // shadow[2]
    // Add more shadows as needed
  ],
});

const App = () => {
  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      <WalletProvider>
        <Router>
          <Navbar /> {/* Navbar is present on all pages */}
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/bet" element={<Bet />} />
              <Route path="/lottery" element={<Lottery />} />
              <Route path="/roulette" element={<Roulette />} />
              <Route path="/coinflip" element={<CoinFlip />} />
              <Route path="/dice" element={<Dice />} /> {/* Dice route */}
              {/* Optional: Add a 404 Not Found route */}
              <Route path="*" element={<NotFound />} /> 
            </Routes>
          </Layout>
        </Router>
      </WalletProvider>
    </ThemeProvider>
  );
};

// Optional: Define NotFound component directly or import if it's in a separate file
const NotFound = () => (
  <div style={{ padding: '2rem', textAlign: 'center' }}>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
);

export default App; 