// src/pages/Roulette.js

import React, { useState, useEffect } from 'react';
import RouletteTable from '../components/table/Table';
import Weel from '../components/weel/Weel'; // Corrected component name
import { Container, Row, Col, Image } from 'react-bootstrap';
import { GiDiamonds } from 'react-icons/gi';
import styled, { keyframes } from 'styled-components';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

// Import JSON data
import firstRowData from '../components/table/rows/FirstRow.json';
import firstBorderData from '../components/table/rows/FirstBorder.json';
import secondRowData from '../components/table/rows/SecondRow.json';
import secondBorderData from '../components/table/rows/SecondBorder.json';
import thirdRowData from '../components/table/rows/ThirdRow.json';
import thirdBorderData from '../components/table/rows/ThirdBorder.json';
import fourthRowData from '../components/table/rows/FourthRow.json';
import fifthRowData from '../components/table/rows/FifthRow.json';
import columnLeftData from '../components/table/rows/ColumnLeft.json';
import columnRightData from '../components/table/rows/ColumnRight.json';

// Keyframes for animations
const neonAnimation = keyframes`
  0%, 100% {
    text-shadow: 0 0 1vw #FA1C16, 0 0 3vw #FA1C16, 0 0 10vw #FA1C16, 0 0 10vw #FA1C16, 0 0 .4vw #FED128, .5vw .5vw .1vw #806914;
    color: #FED128;
  }
  50% {
    text-shadow: 0 0 .5vw #800E0B, 0 0 1.5vw #800E0B, 0 0 5vw #800E0B, 0 0 5vw #800E0B, 0 0 .2vw #800E0B, .5vw .5vw .1vw #40340A;
    color: #806914;
  }
`;

const blinkAnimation = keyframes`
  0% { color: #b0762b; }
  50% { color: #dcb44a; }
  60% { color: #dcb44a; }
  70% { color: #dcb44a; }
  100% { color: #b0762b; }
`;

// Styled Components
const StyledAppContainer = styled(Container)`
  padding: 20px;
`;

const LogoImage = styled(Image)`
  max-height: 120px;
  margin-bottom: 20px;
`;

const StyledTableContainer = styled.div`
  margin: 10px auto;
  padding: 20px;
  border: 2px solid #dcb44a;
  border-radius: 16px;
  background: linear-gradient(to bottom, #121212, #0e1111, #121212); 
  width: 100%;
`;

const VerdictRow = styled(Row)`
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 3px solid #F2F098;
  max-width: 700px !important;
  flex-wrap: wrap;
  word-wrap: break-word;
  min-height: 100px;
  color: #F2F098;
  background-color: #8B0000;
`;

const CoinsCol = styled(Col)`
  background: url("/images/button-bg.png") center center no-repeat;
  background-size: contain;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageText = styled.h6`
  text-transform: uppercase;
  animation: ${neonAnimation} 1s ease infinite;
`;

const DividerLine = styled.div`
  position: relative;
  border-top-width: 1px;
  margin: 0 0 20px 0;
  text-align: center;
  height: 1px;
  border: none;
  background-image: linear-gradient(to right, transparent, #b0762b, #dcb44a, transparent);
`;

const DiamondIcon = styled(GiDiamonds)`
  position: absolute;
  top: -11px;
  width: 34px;
  height: 24px;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  color: #db1f27;
  background-color: #8B0000;
  margin-left: -1.42857rem;
`;

const StyledListInline = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
`;

const ListItem = styled.li`
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  animation: ${blinkAnimation} 1.2s infinite;
`;

const StyledTextLightGold = styled.div`
  color: cornsilk;
`;

// NotFound component (if needed)
const NotFound = () => (
  <div style={{ padding: '2rem', textAlign: 'center' }}>
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
);

const Roulette = () => {
  const [num, setNum] = useState(""); // Winning number
  const [arr, setArr] = useState([]); // Array of bets
  const [count, setCount] = useState(0); // Spins count
  const [wins, setWins] = useState(0); // Wins count
  const [chip, setChip] = useState(10); // Chip value
  const [coins, setCoins] = useState(100000); // Coins count
  const [losses, setLosses] = useState(0); // Losses count
  const [spinning, setSpinning] = useState(false); // Is the wheel spinning?
  const [message, setMessage] = useState("Put your bets and spin the wheel!"); // Message
  const [extArr, setExtArr] = useState([]); // Array to track winning numbers

  // Disclaimer dialog state
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);

  // JSON rows
  const [firstRow, setFirstRow] = useState(firstRowData);
  const [firstBorder, setFirstBorder] = useState(firstBorderData);
  const [secondRow, setSecondRow] = useState(secondRowData);
  const [secondBorder, setSecondBorder] = useState(secondBorderData);
  const [thirdRow, setThirdRow] = useState(thirdRowData);
  const [thirdBorder, setThirdBorder] = useState(thirdBorderData);
  const [fourthRow, setFourthRow] = useState(fourthRowData);
  const [fifthRow, setFifthRow] = useState(fifthRowData);
  const [columnLeft, setColumnLeft] = useState(columnLeftData);
  const [columnRight, setColumnRight] = useState(columnRightData);

  // Declaring combinations
  const twoByOneFirst = ["3", "6", "2", "12", "15", "18", "21", "24", "27", "30", "33", "36"];
  const twoByOneSecond = ["2", "5", "8", "11", "14", "17", "20", "23", "26", "29", "32", "35"];
  const twoByOneThird = ["1", "4", "7", "10", "13", "16", "19", "22", "25", "28", "31", "34"];
  const firstTwelves = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  const secondTwelves = ["13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"];
  const thirdTwelves = ["25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"];
  const oneToEighteen = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18"];
  const nineteenToThirtySix = ["19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36"];
  const black = ["2", "4", "6", "8", "10", "11", "13", "15", "17", "20", "22", "24", "26", "28", "29", "31", "33", "35"];
  const red = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
  const even = ["2", "4", "6", "8", "10", "12", "14", "16", "18", "20", "22", "24", "26", "28", "30", "32", "34", "36"];
  const odd = ['1', '3', '5', '7', '9', '11', '13', '15', '17', '19', '21', '23', '25', '27', '29', '31', '33', '35'];

  useEffect(() => {
    // componentDidMount equivalent
    // Grab user data from database and set state with that data if needed
  }, []);

  const isSpinning = (isspinning) => {
    setSpinning(isspinning);
  };

  // Handling losing
  const userLost = () => {
    setMessage("No luck this time!");
    setLosses(prev => prev + 1);
    resetGame();
    // Send data to MongoDB if needed
  };

  // Handling winning
  const userWin = (multi) => {
    const winAmount = multi * parseInt(chip);
    setMessage(`You win ${winAmount} coins!`);
    setWins(prev => prev + 1);
    setCoins(prev => prev + winAmount);
    resetGame();
    // Send data to MongoDB if needed
  };

  // Reset game function: emptying the array and setting all the chips to invisible state
  const resetGame = () => {
    setArr([]);
    setSpinning(false);
    setNum("");
    setFirstRow(prev => prev.map(num => ({ ...num, visible: false })));
    setFirstBorder(prev => prev.map(num => ({ ...num, visible: false })));
    setSecondRow(prev => prev.map(num => ({ ...num, visible: false })));
    setSecondBorder(prev => prev.map(num => ({ ...num, visible: false })));
    setThirdRow(prev => prev.map(num => ({ ...num, visible: false })));
    setThirdBorder(prev => prev.map(num => ({ ...num, visible: false })));
    setFourthRow(prev => prev.map(num => ({ ...num, visible: false })));
    setFifthRow(prev => prev.map(num => ({ ...num, visible: false })));
    setColumnLeft(prev => prev.map(num => ({ ...num, visible: false })));
    setColumnRight(prev => prev.map(num => ({ ...num, visible: false })));
    setExtArr([]);
  };

  // Checking arrays for win conditions
  const determineValidBets = (length, element, num, multiplier) => {
    if (element.length === length) {
      const filtering = element.filter(isItMyNum => isItMyNum === num);
      if (filtering.includes(num)) {
        setExtArr(prev => [...prev, num]);
        userWin(multiplier);
      }
    }
  };

  const determineValidBetsColFive = (name, element, arrName, num, multiplier) => {
    if (element === name) {
      const filtered = arrName.filter(item => item === num);
      if (filtered.includes(num)) {
        setExtArr(prev => [...prev, num]);
        userWin(multiplier);
      }
    }
  };

  // Function to update winning number and determine if user won or lost
  const updateNum = (num) => {
    setNum(num);
    setCount(prev => prev + 1);

    arr.forEach(item => {
      if (item === num) { // Single number bet
        userWin(35); // Multiplier is 35
      }

      if (typeof item !== "string") { // Array-based bets
        determineValidBets(2, item, num, 17);
        determineValidBets(3, item, num, 11);
        determineValidBets(4, item, num, 8);
        determineValidBets(6, item, num, 5);
      } else { // String-based bets (e.g., "Even", "Odd")
        determineValidBetsColFive("Even", item, even, num, 1);
        determineValidBetsColFive("Odd", item, odd, num, 1);
        determineValidBetsColFive("Black", item, black, num, 1);
        determineValidBetsColFive("Red", item, red, num, 1);
        determineValidBetsColFive("1 to 18", item, oneToEighteen, num, 1);
        determineValidBetsColFive("19 to 36", item, nineteenToThirtySix, num, 1);
        determineValidBetsColFive("3rd 12", item, thirdTwelves, num, 1);
        determineValidBetsColFive("2nd 12", item, secondTwelves, num, 1);
        determineValidBetsColFive("1st 12", item, firstTwelves, num, 1);
        determineValidBetsColFive("2:1:1", item, twoByOneFirst, num, 2);
        determineValidBetsColFive("2:1:2", item, twoByOneSecond, num, 2);
        determineValidBetsColFive("2:1:3", item, twoByOneThird, num, 2);
      }
    });

    // If no winning numbers, user lost
    if (extArr.length === 0) {
      userLost();
    }
  };

  // Functions to update state from child components
  const updateArr = (arr) => {
    setArr(arr);
  };

  const updateCoins = (coins) => {
    setCoins(coins);
  };

  const updateRow = (row, val) => {
    switch(row) {
      case 'firstRow':
        setFirstRow(val);
        break;
      case 'firstBorder':
        setFirstBorder(val);
        break;
      case 'secondRow':
        setSecondRow(val);
        break;
      case 'secondBorder':
        setSecondBorder(val);
        break;
      case 'thirdRow':
        setThirdRow(val);
        break;
      case 'thirdBorder':
        setThirdBorder(val);
        break;
      case 'fourthRow':
        setFourthRow(val);
        break;
      case 'fifthRow':
        setFifthRow(val);
        break;
      case 'columnLeft':
        setColumnLeft(val);
        break;
      case 'columnRight':
        setColumnRight(val);
        break;
      default:
        break;
    }
  };

  return (
    <StyledAppContainer fluid>
      {/* Disclaimer Popup */}
      <Dialog open={isDisclaimerOpen} onClose={() => {}} disableEscapeKeyDown>
        <DialogTitle>Coming Soon</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This feature is currently under development. UI is a work in progress.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDisclaimerOpen(false)} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Row className="justify-content-center pt-2">
        {/* Logo */}
        <Col xs={12} className="text-center mb-4">
          <LogoImage src="/images/shic_logo2.png" className="img-fluid" alt="Logo" />
        </Col>

        {/* Main Roulette Container */}
        <StyledTableContainer>
          <Row className="align-items-center">
            {/* Roulette Table and Verdict */}
            <Col md={6} className="mb-4 mb-md-0">
              <RouletteTable
                // ROWS
                firstRow={firstRow}
                firstBorder={firstBorder}
                secondRow={secondRow}
                secondBorder={secondBorder}
                thirdRow={thirdRow}
                thirdBorder={thirdBorder}
                fourthRow={fourthRow}
                fifthRow={fifthRow}
                columnLeft={columnLeft}
                columnRight={columnRight}
                // END ROWS
                updateRow={updateRow}
                updateArr={updateArr}
                updateCoins={updateCoins}
                num={num}
                arr={arr}
                count={count}
                coins={coins}
                chip={chip}
                spinning={spinning}
                isDisclaimerOpen={isDisclaimerOpen} // Pass to disable bets if needed
              />
              <VerdictRow>
                <CoinsCol md={4}>
                  <h4 className="m-0">${coins}</h4>
                </CoinsCol>
                <Col md={8}>
                  <div className="text-center">
                    <MessageText>{message}</MessageText>
                  </div>
                  <div className="text-center position-relative">
                    <DividerLine>
                      <DiamondIcon />
                    </DividerLine>
                    <StyledListInline>
                      <ListItem>Spins: {count}</ListItem>
                      <ListItem>Wins: {wins}</ListItem>
                      <ListItem>Losses: {losses}</ListItem>
                    </StyledListInline>
                  </div>
                </Col>
              </VerdictRow>
            </Col>

            {/* Wheel */}
            <Col md={6} className="text-center">
              <Weel
                isSpinning={isSpinning}
                updateNum={updateNum}
                num={num}
                arr={arr}
                count={count}
                isDisclaimerOpen={isDisclaimerOpen} // Pass to disable spinning if needed
              />
            </Col>
          </Row>
        </StyledTableContainer>

        {/* Bets Display */}
        <StyledTableContainer fluid>
          <Row>
            <StyledTextLightGold>
              Your bets: {arr.join(", ")}
            </StyledTextLightGold>
          </Row>
        </StyledTableContainer>
      </Row>
    </StyledAppContainer>
  );
};

export default Roulette;
