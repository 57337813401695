import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Card, Typography, Button, Box, TextField, MenuItem, Select, InputLabel, FormControl, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { motion } from 'framer-motion';

const Container = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: 350,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  textAlign: 'center',
  boxShadow: '0 8px 12px rgba(0,0,0,0.1)',
  backgroundColor: '#ffffff',
  position: 'relative',
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  fontSize: '1.5rem',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 4),
  textTransform: 'none',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const DiceContainer = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  margin: '0 auto',
  perspective: 1000,
  position: 'relative',
}));

const DiceFace = styled(motion.div)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
  alignItems: 'center',
  justifyItems: 'center',
  background: 'linear-gradient(135deg, #fafafa, #ddd)',
  borderRadius: theme.spacing(1),
  boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
}));

const Dot = styled('div')(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: '50%',
  backgroundColor: '#000',
  visibility: 'hidden',
}));

// Set a multiplier for winnings
const MULTIPLIER = 6;

export default function DiceRoll() {
  const [outcome, setOutcome] = useState(1); // Current shown outcome (1 to 6)
  const [isRolling, setIsRolling] = useState(false);

  const [betChoice, setBetChoice] = useState(1); // User's chosen number
  const [betAmount, setBetAmount] = useState(10);
  const [userBalance, setUserBalance] = useState(100);

  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);

  const rollDice = () => {
    if (betAmount > userBalance) {
      alert('You do not have enough balance.');
      return;
    }

    setIsRolling(true);

    const finalOutcome = Math.floor(Math.random() * 6) + 1;

    // Change dice faces at multiple intervals for a more dynamic animation
    const intermediateChanges = [500, 1000, 1500]; // times in ms
    const timeouts = [];

    intermediateChanges.forEach((time) => {
      const timeout = setTimeout(() => {
        // Show a random face (not necessarily final)
        const randomFace = Math.floor(Math.random() * 6) + 1;
        setOutcome(randomFace);
      }, time);
      timeouts.push(timeout);
    });

    // Final outcome at 2s
    const finalTimeout = setTimeout(() => {
      setOutcome(finalOutcome);
      setIsRolling(false);
      // Resolve bet
      if (betChoice === finalOutcome) {
        setUserBalance((prev) => prev + betAmount * MULTIPLIER);
      } else {
        setUserBalance((prev) => prev - betAmount);
      }
      // Clear timeouts (just a safety measure)
      timeouts.forEach((t) => clearTimeout(t));
    }, 2000);

    // Cleanup if component unmounts mid-roll
    // Not strictly needed here, but good practice:
    return () => {
      clearTimeout(finalTimeout);
      timeouts.forEach((t) => clearTimeout(t));
    };
  };

  const handleCloseDisclaimer = () => {
    setIsDisclaimerOpen(false);
  };

  // Determine which dots should be visible for the current outcome
  const dotVisibility = Array(9).fill(false);
  switch (outcome) {
    case 1:
      dotVisibility[4] = true;
      break;
    case 2:
      dotVisibility[0] = true;
      dotVisibility[8] = true;
      break;
    case 3:
      dotVisibility[0] = true;
      dotVisibility[4] = true;
      dotVisibility[8] = true;
      break;
    case 4:
      dotVisibility[0] = true;
      dotVisibility[2] = true;
      dotVisibility[6] = true;
      dotVisibility[8] = true;
      break;
    case 5:
      dotVisibility[0] = true;
      dotVisibility[2] = true;
      dotVisibility[4] = true;
      dotVisibility[6] = true;
      dotVisibility[8] = true;
      break;
    case 6:
      dotVisibility[0] = true;
      dotVisibility[2] = true;
      dotVisibility[3] = true;
      dotVisibility[5] = true;
      dotVisibility[6] = true;
      dotVisibility[8] = true;
      break;
    default:
      break;
  }

  // Animation variants for dice roll
  // Multiple rotations and slight scaling for more natural feel.
  const rollAnimation = isRolling
    ? {
        rotateX: [0, 120, 240, 360],
        rotateY: [0, 90, 180, 270, 360],
        scale: [1, 1.1, 1, 1.05, 1],
      }
    : { rotateX: 0, rotateY: 0, scale: 1 };

  const rollTransition = {
    duration: 2,
    ease: 'easeInOut',
  };

  return (
    <>
      <Dialog open={isDisclaimerOpen} onClose={() => {}} disableEscapeKeyDown>
        <DialogTitle>Coming Soon</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This feature is currently under development.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisclaimer} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Container>
        <StyledCard>
          <HeaderTypography variant="h5" color="text.primary">
            Roll the Dice
          </HeaderTypography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            Pick a number (1-6), place your bet, and roll!
          </Typography>

          {/* Betting Interface */}
          <Box sx={{ textAlign: 'left', mb: 2 }}>
            <Typography variant="subtitle1">
              <strong>Balance:</strong> {userBalance} credits
            </Typography>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel>Pick a Number</InputLabel>
              <Select
                value={betChoice}
                label="Pick a Number"
                onChange={(e) => setBetChoice(Number(e.target.value))}
              >
                {[1, 2, 3, 4, 5, 6].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Bet Amount"
              type="number"
              value={betAmount}
              onChange={(e) => setBetAmount(Number(e.target.value))}
              sx={{ mt: 2 }}
            />
          </Box>

          <DiceContainer>
            <DiceFace animate={rollAnimation} transition={rollTransition}>
              {dotVisibility.map((visible, idx) => (
                <Dot key={idx} style={{ visibility: visible ? 'visible' : 'hidden' }} />
              ))}
            </DiceFace>
          </DiceContainer>

          <StyledButton onClick={rollDice} disabled={isRolling || isDisclaimerOpen}>
            {isRolling ? 'Rolling...' : 'Roll Dice'}
          </StyledButton>

          <Typography variant="h6" sx={{ marginTop: 2, fontWeight: 'bold', minHeight: '1.5em' }}>
            {!isRolling ? `Outcome: ${outcome}` : 'Outcome: ...'}
          </Typography>

          {!isRolling && (
            <Typography variant="body1" sx={{ mt: 1 }}>
              {betChoice === outcome
                ? `You Won ${betAmount * MULTIPLIER} credits!`
                : `You Lost ${betAmount} credits.`}
            </Typography>
          )}
        </StyledCard>
      </Container>
    </>
  );
}
