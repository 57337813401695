import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  CircularProgress,
  CssBaseline,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Tooltip,
  Collapse,
} from '@mui/material';
import { styled, useTheme, alpha } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import PlaceBet from '../components/PlaceBet';
import DownloadButton from '../components/DownloadButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { WalletContext } from '../WalletProvider';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'; // AI Icon
import ReceiptIcon from '@mui/icons-material/Receipt'; // Transactions Icon
import CloseIcon from '@mui/icons-material/Close'; // Close Icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChatIcon from '@mui/icons-material/Chat'; // New chat room icon

// ---------------------- Styled Components ----------------------

const ElegantButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ff301a', // New red color
  color: theme.palette.common.white,
  fontFamily: "'Schoolbell', cursive",
  border: `2px solid ${theme.palette.common.black}`,
  boxShadow: theme.shadows[2],
  minWidth: '100px',
  padding: theme.spacing(0.75, 1.5),
  fontSize: '1rem',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: '#d92916', 
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '80px',
    padding: theme.spacing(0.5, 1),
    fontSize: '0.9rem',
  },
}));

const SecondaryElegantButton = styled(Button)(({ theme }) => ({
  backgroundColor: alpha('#ff301a', 0.8),
  color: theme.palette.common.white,
  fontFamily: "'Schoolbell', cursive",
  border: `2px solid ${theme.palette.common.black}`,
  boxShadow: theme.shadows[1],
  minWidth: '100px',
  padding: theme.spacing(0.5, 1),
  fontSize: '0.9rem',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    backgroundColor: alpha('#d92916', 0.9),
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '80px',
    padding: theme.spacing(0.5, 1),
    fontSize: '0.8rem',
  },
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: '#ff301a',
  transition: 'color 0.3s',
  '&:hover': {
    color: '#d92916',
  },
}));

const AppContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
  backgroundImage: 'url(/path-to-your-wallpaper.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const MainContent = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const TopBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  marginBottom: theme.spacing(4),
}));

const FilterActionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  flexWrap: 'nowrap',
  width: '100%',
  justifyContent: 'space-between',
}));

const BalanceContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  borderRadius: theme.shape.borderRadius,
}));

const AssetCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: 'none',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const CircularImage = styled('img')(({ size }) => ({
  width: size || 32,
  height: size || 32,
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: 12,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius * 2,
    border: `2px solid ${theme.palette.grey[400]}`,
    boxShadow: theme.shadows[4],
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    width: '100%',
    maxWidth: '600px',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
      padding: theme.spacing(1),
    },
  },
}));

const ChatMessageContainer = styled(Box)(({ theme, sender }) => ({
  display: 'flex',
  justifyContent: sender === 'user' ? 'flex-end' : 'flex-start',
  marginBottom: theme.spacing(1),
}));

const ChatBubble = styled(Box)(({ theme, sender }) => ({
  backgroundColor:
    sender === 'user' ? '#ff301a' : alpha('#ff301a', 0.8),
  color: theme.palette.common.white,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  maxWidth: '80%',
  fontFamily: "'Schoolbell', cursive",
  wordWrap: 'break-word',
}));

const TransactionListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.85)',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  boxShadow: theme.shadows[1],
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
}));

const themeConfig = createTheme({
  palette: {
    primary: {
      main: '#ff301a', 
      dark: '#d92916', 
    },
    secondary: {
      main: '#f44336', 
    },
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: "'Schoolbell', cursive",
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 1px 3px rgba(0,0,0,0.2)',
    '0px 3px 6px rgba(0,0,0,0.3)',
    '0px 10px 20px rgba(0,0,0,0.19)',
    '0px 14px 28px rgba(0,0,0,0.25)',
  ],
});

const BetPage = () => {
  const { lucid, walletAddress, isConnected } = useContext(WalletContext);

  const [betInfo, setBetInfo] = useState([]); 
  const [selectedTopic, setSelectedTopic] = useState('All');
  const [chatOpen, setChatOpen] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [balances, setBalances] = useState({
    ADA: 0,
    ERMO: 0,
  });
  const [transactionOpen, setTransactionOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [desktopPopupOpen, setDesktopPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const [selectedBetFilter, setSelectedBetFilter] = useState('All');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('All');
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [expandedTransactions, setExpandedTransactions] = useState({});
  const chatEndRef = useRef(null);

  const currentTheme = useTheme(); 
  const isMobile = useMediaQuery(currentTheme.breakpoints.down('sm')); 

  const blockfrostApiKey = process.env.REACT_APP_BLOCKFROST_API_KEY; 
  const chatGptApiKey = process.env.REACT_APP_CHATGPT_API_KEY; 

  const assetDetails = {
    ADA: {
      unit: 'lovelace',
      name: 'ADA',
      image: '/ada.png',
      policyId: '',
      fingerprint: '',
    },
    ERMO: {
      unit: 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f645524d4f',
      name: 'ERMO',
      image: '/ermo.png',
      policyId: 'e1a212122b07460a739af69751bd01cd03174791f778b2365cc0b8f6',
      fingerprint: '',
    },
  };

  // Chat Room Feature
  const [chatRoomOpen, setChatRoomOpen] = useState(false);
  const [chatRoomMessages, setChatRoomMessages] = useState([]);
  const [chatRoomInput, setChatRoomInput] = useState('');
  const [isChatRoomLoading, setIsChatRoomLoading] = useState(false);
  const chatRoomEndRef = useRef(null);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/betInfo.json`)
      .then(response => response.json())
      .then(data => {
        setBetInfo(data);
        console.log('Loaded betInfo:', data);
      })
      .catch(error => console.error("Error loading betInfo:", error));
  }, []);

  useEffect(() => {
    if (isConnected && walletAddress && betInfo.length > 0) {
      fetchBalances();
      fetchTransactions();
    }
  }, [isConnected, walletAddress, betInfo]);

  useEffect(() => {
    if (!isMobile) {
      setDesktopPopupOpen(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (chatOpen) {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages, chatOpen]);

  useEffect(() => {
    if (chatRoomOpen) {
      chatRoomEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatRoomMessages, chatRoomOpen]);

  const fetchBalances = async () => {
    if (!lucid) {
      console.error('Lucid is not initialized.');
      return;
    }

    try {
      const utxos = await lucid.wallet.getUtxos();
      let adaBalance = 0;
      let ermoBalance = 0;

      utxos.forEach((utxo) => {
        adaBalance += parseInt(utxo.assets['lovelace'] || 0, 10) / 1_000_000;

        Object.entries(utxo.assets).forEach(([key, amount]) => {
          if (key.includes(assetDetails.ERMO.unit)) {
            ermoBalance += parseInt(amount, 10);
          }
        });
      });

      setBalances({
        ADA: adaBalance,
        ERMO: ermoBalance,
      });
    } catch (error) {
      console.error('Error fetching balances:', error);
      alert('An error occurred while fetching balances. Please check the console for more details.');
    }
  };

  const fetchTransactions = async () => {
    if (!lucid) {
      console.error('Lucid is not initialized.');
      return;
    }

    try {
      if (!walletAddress) {
        console.warn('No wallet address connected.');
        return;
      }

      let allTxs = [];
      const betWalletAddresses = new Set(
        betInfo.map((bet) => bet.walletAddress.toLowerCase())
      );

      setIsLoading(true);

      const concurrencyLimit = 5;
      const queue = [...betInfo];
      let activePromises = [];

      const fetchBatch = async () => {
        if (queue.length === 0) return;

        while (activePromises.length < concurrencyLimit && queue.length > 0) {
          const bet = queue.shift();
          const betWalletAddress = bet.walletAddress.toLowerCase();

          const fetchAllPages = async () => {
            let page = 1;
            const limit = 100;
            let hasMore = true;

            while (hasMore) {
              try {
                const txResponse = await axios.get(
                  `https://cardano-mainnet.blockfrost.io/api/v0/addresses/${betWalletAddress}/transactions`,
                  {
                    headers: {
                      project_id: blockfrostApiKey,
                    },
                    params: {
                      page,
                      count: limit,
                    },
                  }
                );

                const txData = txResponse.data;

                if (txData.length === 0) {
                  hasMore = false;
                  break;
                }

                allTxs = [...allTxs, ...txData];

                if (txData.length < limit) {
                  hasMore = false;
                } else {
                  page += 1;
                }
              } catch (error) {
                console.error('Error fetching transactions:', error);
                hasMore = false;
              }
            }
          };

          const promise = fetchAllPages();
          activePromises.push(promise);

          promise.then(() => {
            activePromises = activePromises.filter((p) => p !== promise);
          });
        }

        await Promise.all(activePromises);
        await fetchBatch();
      };

      await fetchBatch();

      const incomingTxs = [];
      const uniqueTxHashes = new Set();

      for (let tx of allTxs) {
        if (uniqueTxHashes.has(tx.tx_hash)) {
          continue;
        }
        uniqueTxHashes.add(tx.tx_hash);

        let txDetails = {};
        try {
          const txDetailsResponse = await axios.get(
            `https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}`,
            {
              headers: {
                project_id: blockfrostApiKey,
              },
            }
          );
          txDetails = txDetailsResponse.data;
        } catch {
          continue;
        }

        let utxos = {};
        try {
          const txUTXOsResponse = await axios.get(
            `https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}/utxos`,
            {
              headers: {
                project_id: blockfrostApiKey,
              },
            }
          );
          utxos = txUTXOsResponse.data;
        } catch {
          continue;
        }

        const isSender =
          utxos.inputs &&
          utxos.inputs.some(
            (input) => input.address.toLowerCase() === walletAddress.toLowerCase()
          );

        if (isSender) {
          let metadata = [];
          try {
            const txMetadataResponse = await axios.get(
              `https://cardano-mainnet.blockfrost.io/api/v0/txs/${tx.tx_hash}/metadata`,
              {
                headers: {
                  project_id: blockfrostApiKey,
                },
              }
            );
            metadata = txMetadataResponse.data;
          } catch {}

          const relatedOutputs = utxos.outputs.filter((output) =>
            betWalletAddresses.has(output.address.toLowerCase())
          );

          if (relatedOutputs.length > 0) {
            relatedOutputs.forEach((output) => {
              const assets = output.amount.map((amount) => {
                if (amount.unit === 'lovelace') {
                  return { asset: 'ADA', amount: amount.quantity / 1_000_000 };
                } else {
                  if (amount.unit.includes(assetDetails.ERMO.unit)) {
                    return { asset: 'ERMO', amount: amount.quantity };
                  }
                }
                return null;
              }).filter(Boolean);

              const nonAdaAssets = assets.filter((asset) => asset.asset !== 'ADA');
              const displayAssets = nonAdaAssets.length > 0 ? nonAdaAssets : assets;

              const timestamp = new Date(txDetails.block_time * 1000).toLocaleString();

              const betInfoData = metadata.length > 0 ? metadata[0]?.json_metadata : null;
              const betId = betInfoData?.betId;
              const associatedBet = betId ? betInfo.find((bet) => bet.id === betId) : null;
              const betTitle = associatedBet?.title || 'Unknown Bet';
              const startDate = associatedBet?.startDate;
              const endDate = associatedBet?.endDate;

              let betStatus = 'Unknown';
              if (startDate && endDate) {
                const now = new Date();
                const start = new Date(startDate);
                const end = new Date(endDate);

                if (now >= start && now <= end) {
                  betStatus = 'Bet Live';
                } else if (now > end) {
                  betStatus = 'Bet Ended';
                } else {
                  betStatus = 'Bet Not Started';
                }
              }

              incomingTxs.push({
                hash: tx.tx_hash,
                sender: walletAddress,
                assets: displayAssets,
                betTitle: betTitle,
                result: betInfoData?.result,
                time: timestamp,
                betStatus: betStatus,
              });
            });
          }
        }
      }

      incomingTxs.sort((a, b) => new Date(b.time) - new Date(a.time));
      setTransactions(incomingTxs);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      alert('An error occurred while fetching transactions.');
    } finally {
      setIsLoading(false);
    }
  };

  // Initialize Chat with ERMO AI
  const initializeChat = async () => {
    if (!chatGptApiKey) {
      console.error('ChatGPT API key is missing.');
      alert('ChatGPT API key is missing. Please contact support.');
      return;
    }

    try {
      setIsChatLoading(true);

      const activeBets = betInfo.filter((bet) => bet.type !== 'Closed');

      const detailedBetInfo = activeBets
        .map(
          (bet) =>
            `${bet.title} - Description: ${bet.description || 'N/A'}, Results: ${
              bet.result?.join(', ') || 'N/A'
            }`
        )
        .join('; ');

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${chatGptApiKey}`,
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: [
            {
              role: 'system',
              content:
                "You are ERMO AI, a confident, risk-taking betting advisor.",
            },
            {
              role: 'system',
              content: `Here is some context about the current webpage: ${document.documentElement.innerText}`,
            },
            {
              role: 'system',
              content: `Currently available bets with details: ${detailedBetInfo}`,
            },
          ],
          max_tokens: 800,
        }),
      });

      const data = await response.json();
      if (data.choices && data.choices.length > 0) {
        let botMessage = data.choices[0].message.content.trim();
        botMessage = botMessage.replace(/\n\s*\n/g, '\n');

        setChatMessages((prev) => [...prev, { sender: 'bot', text: botMessage }]);
      } else {
        setChatMessages((prev) => [
          ...prev,
          { sender: 'bot', text: 'Sorry, no response.' },
        ]);
      }
    } catch (error) {
      console.error('Error initializing chat with ERMO AI:', error);
      setChatMessages((prev) => [
        ...prev,
        {
          sender: 'bot',
          text: 'Something went wrong. Please try again later.',
        },
      ]);
    } finally {
      setIsChatLoading(false);
    }
  };

  const handleChatBubbleClick = () => {
    setChatOpen(true);
    if (!isChatInitialized) {
      initializeChat();
      setIsChatInitialized(true);
    }
  };

  const handleTransactionBubbleClick = () => {
    setTransactionOpen(true);
  };

  const handleChatClose = () => {
    setChatOpen(false);
  };

  const handleTransactionClose = () => {
    setTransactionOpen(false);
  };

  const handleChatInputChange = (event) => {
    setChatInput(event.target.value);
  };

  const handleSendMessage = async () => {
    if (chatInput.trim() !== '') {
      const userMessage = chatInput;
      setChatMessages((prev) => [...prev, { sender: 'user', text: userMessage }]);
      setChatInput('');

      if (!chatGptApiKey) {
        console.error('ChatGPT API key is missing.');
        setChatMessages((prev) => [
          ...prev,
          { sender: 'bot', text: 'API key missing.' },
        ]);
        return;
      }

      try {
        setIsChatLoading(true);
        const activeBets = betInfo.filter((bet) => bet.type !== 'Closed');

        const detailedBetInfo = activeBets
          .map(
            (bet) =>
              `${bet.title} - Description: ${bet.description || 'N/A'}, Results: ${
                bet.result?.join(', ') || 'N/A'
              }`
          )
          .join('; ');

        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${chatGptApiKey}`,
          },
          body: JSON.stringify({
            model: 'gpt-4',
            messages: [
              {
                role: 'system',
                content:
                  "You are ERMO AI, a confident betting advisor.",
              },
              {
                role: 'system',
                content: `Here is context: ${document.documentElement.innerText}`,
              },
              {
                role: 'system',
                content: `Bets: ${detailedBetInfo}`,
              },
              ...chatMessages.map((msg) => ({
                role: msg.sender === 'user' ? 'user' : 'assistant',
                content: msg.text,
              })),
              { role: 'user', content: userMessage },
            ],
            max_tokens: 800,
          }),
        });

        const data = await response.json();
        if (data.choices && data.choices.length > 0) {
          let botMessage = data.choices[0].message.content.trim();
          botMessage = botMessage.replace(/\n\s*\n/g, '\n');

          setChatMessages((prev) => [...prev, { sender: 'bot', text: botMessage }]);
        } else {
          setChatMessages((prev) => [
            ...prev,
            { sender: 'bot', text: 'No response available.' },
          ]);
        }
      } catch (error) {
        console.error('Error from ChatGPT:', error);
        setChatMessages((prev) => [
          ...prev,
          { sender: 'bot', text: 'Error occurred.' },
        ]);
      } finally {
        setIsChatLoading(false);
      }
    }
  };

  const closeDesktopPopup = () => {
    setDesktopPopupOpen(false);
  };

  const handleToggleExpand = (txHash) => {
    setExpandedTransactions((prev) => ({
      ...prev,
      [txHash]: !prev[txHash],
    }));
  };

  const betTypes = ['All', ...new Set(betInfo.map((bet) => bet.type))];
  const betTitles = ['All', ...new Set(betInfo.map((bet) => bet.title))];
  const statusOptions = ['All', 'Bet Live', 'Bet Ended', 'Bet Not Started'];

  const filteredBets =
    selectedTopic === 'All'
      ? betInfo.filter((bet) => bet.type !== 'Closed')
      : betInfo.filter((bet) => bet.type === selectedTopic);

  const applyFilters = () => {
    let filtered = [...transactions];

    if (selectedBetFilter !== 'All') {
      filtered = filtered.filter((tx) => tx.betTitle === selectedBetFilter);
    }

    if (selectedStatusFilter !== 'All') {
      filtered = filtered.filter((tx) => tx.betStatus === selectedStatusFilter);
    }

    return filtered;
  };

  const filteredTransactions = applyFilters();

  // Chat Room Handlers
  const handleChatRoomClick = async () => {
    setChatRoomOpen(true);
    await fetchChatRoomMessages();
  };

  const handleChatRoomClose = () => {
    setChatRoomOpen(false);
  };

  const handleChatRoomInputChange = (e) => {
    setChatRoomInput(e.target.value);
  };

  const fetchChatRoomMessages = async () => {
    try {
      setIsChatRoomLoading(true);
      const res = await fetch('/api/messages');
      const data = await res.json();
      setChatRoomMessages(data);
    } catch (error) {
      console.error('Error fetching chat room messages:', error);
    } finally {
      setIsChatRoomLoading(false);
    }
  };

  const handleSendChatRoomMessage = async () => {
    if (!walletAddress) {
      alert('Connect your wallet to send messages.');
      return;
    }
    if (chatRoomInput.trim() !== '') {
      const newMessage = {
        sender: walletAddress,
        text: chatRoomInput.trim(),
      };
      setChatRoomMessages((prev) => [...prev, newMessage]);
      setChatRoomInput('');

      try {
        await fetch('/api/messages', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newMessage),
        });
      } catch (error) {
        console.error('Error sending chat room message:', error);
        alert('Could not send message. Please try again.');
      }
    }
  };

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      <AppContainer>
        <MainContent>
          {/* Top Bar with Filter and Buttons */}
          <TopBar>
            <FilterActionContainer>
              {/* Action Buttons */}
              <Box sx={{ display: 'flex', gap: 2 }}>
                {/* AI Button */}
                <Tooltip title="Chat with ERMO AI" arrow>
                  <ElegantButton
                    startIcon={<EmojiPeopleIcon />}
                    onClick={handleChatBubbleClick}
                    aria-label="Chat with ERMO AI"
                  >
                    {!isMobile && 'AI'}
                  </ElegantButton>
                </Tooltip>

                {/* Transaction History Button */}
                <Tooltip title="View Transaction History" arrow>
                  <ElegantButton
                    startIcon={<ReceiptIcon />}
                    onClick={handleTransactionBubbleClick}
                    aria-label="View Transaction History"
                  >
                    {!isMobile && 'Transactions'}
                  </ElegantButton>
                </Tooltip>

                {/* Chat Room Button */}
                <Tooltip title="Open Chat Room" arrow>
                  <ElegantButton
                    startIcon={<ChatIcon />}
                    onClick={handleChatRoomClick}
                    aria-label="Open Chat Room"
                  >
                    {!isMobile && 'Chat Room'}
                  </ElegantButton>
                </Tooltip>
              </Box>

              {/* Filter Select */}
              <FormControl
                variant="outlined"
                size="small"
                sx={{ minWidth: 150, flexShrink: 0 }} 
              >
                <Select
                  value={selectedTopic}
                  onChange={(e) => setSelectedTopic(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Topic Filter' }}
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: 1,
                  }}
                >
                  <MenuItem value="All">All Topics</MenuItem>
                  {betTypes.slice(1).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FilterActionContainer>
          </TopBar>

          {/* Balances */}
          {isConnected && walletAddress && (
            <BalanceContainer>
              <Grid container spacing={2}>
                {Object.values(assetDetails)
                  .filter((asset) => balances[asset.name] > 0)
                  .map((asset) => (
                    <Grid item xs={6} sm={4} md={3} key={asset.name}>
                      <AssetCard>
                        <CircularImage
                          src={asset.image}
                          alt={asset.name}
                          size={32}
                        />
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontFamily: "'Schoolbell', cursive",
                              fontWeight: 'bold',
                            }}
                          >
                            {asset.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "'Schoolbell', cursive",
                            }}
                          >
                            {balances[asset.name].toLocaleString(undefined, {
                              minimumFractionDigits: asset.name === 'ERMO' ? 0 : 2,
                              maximumFractionDigits: asset.name === 'ERMO' ? 0 : 2,
                            })}
                          </Typography>
                        </Box>
                      </AssetCard>
                    </Grid>
                  ))}
              </Grid>
            </BalanceContainer>
          )}

          {/* Bets Grid */}
          <Grid
            container
            direction="column"
            alignItems="center"
            spacing={4}
            sx={{
              marginTop: isMobile ? 2.5 : 5,
            }}
          >
            {filteredBets.map((bet) => (
              <Grid item key={bet.id} xs={12} sm={10} md={8} lg={6} sx={{ width: '100%', maxWidth: '600px' }}>
                <PlaceBet bet={bet} lucid={lucid} walletAddress={walletAddress} />
              </Grid>
            ))}
          </Grid>
        </MainContent>

        {/* Chat Dialog (ERMO AI) */}
        <StyledDialog open={chatOpen} onClose={handleChatClose}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButtonStyled onClick={handleChatClose} aria-label="Close Chat">
              <CloseIcon />
            </IconButtonStyled>
          </Box>
          <DialogTitle
            sx={{
              fontFamily: "'Schoolbell', cursive",
              textAlign: 'center',
              borderBottom: `2px solid ${themeConfig.palette.grey[300]}`,
            }}
          >
            Chat with ERMO AI
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '400px' }}>
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  padding: 2,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  borderRadius: 1,
                  marginBottom: 2,
                }}
              >
                {chatMessages.map((message, index) => (
                  <ChatMessageContainer key={index} sender={message.sender}>
                    <ChatBubble sender={message.sender}>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {message.text}
                      </ReactMarkdown>
                    </ChatBubble>
                  </ChatMessageContainer>
                ))}

                {isChatLoading && (
                  <ChatMessageContainer sender="bot">
                    <ChatBubble sender="bot">
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CircularProgress size={16} sx={{ mr: 1 }} />
                        <Typography variant="body2">ERMO AI is typing...</Typography>
                      </Box>
                    </ChatBubble>
                  </ChatMessageContainer>
                )}

                <div ref={chatEndRef} />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={chatInput}
                  onChange={handleChatInputChange}
                  placeholder="Type your message..."
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: 1,
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleSendMessage();
                    }
                  }}
                />
                <ElegantButton
                  variant="contained"
                  onClick={handleSendMessage}
                  disabled={isChatLoading}
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                  }}
                >
                  Send
                </ElegantButton>
              </Box>
            </Box>
          </DialogContent>
        </StyledDialog>

        {/* Chat Room Dialog */}
        <StyledDialog open={chatRoomOpen} onClose={handleChatRoomClose}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButtonStyled onClick={handleChatRoomClose} aria-label="Close Chat Room">
              <CloseIcon />
            </IconButtonStyled>
          </Box>
          <DialogTitle
            sx={{
              fontFamily: "'Schoolbell', cursive",
              textAlign: 'center',
              borderBottom: `2px solid ${themeConfig.palette.grey[300]}`,
            }}
          >
            Community Chat Room
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '400px' }}>
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  padding: 2,
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  borderRadius: 1,
                  marginBottom: 2,
                }}
              >
                {isChatRoomLoading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress color="primary" />
                  </Box>
                ) : chatRoomMessages.length > 0 ? (
                  chatRoomMessages.map((message, index) => (
                    <ChatMessageContainer key={index} sender={message.sender === walletAddress ? 'user' : 'bot'}>
                      <ChatBubble sender={message.sender === walletAddress ? 'user' : 'bot'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                          {message.sender.slice(0, 10)}...: 
                        </Typography>
                        <Typography variant="body2">
                          {message.text}
                        </Typography>
                      </ChatBubble>
                    </ChatMessageContainer>
                  ))
                ) : (
                  <Typography
                    sx={{
                      fontFamily: "'Schoolbell', cursive",
                      textAlign: 'center',
                      mt: 2,
                    }}
                  >
                    No messages yet. Be the first to say something!
                  </Typography>
                )}
                <div ref={chatRoomEndRef} />
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  value={chatRoomInput}
                  onChange={handleChatRoomInputChange}
                  placeholder="Type your message..."
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: 1,
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      handleSendChatRoomMessage();
                    }
                  }}
                />
                <ElegantButton
                  variant="contained"
                  onClick={handleSendChatRoomMessage}
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                  }}
                >
                  Send
                </ElegantButton>
              </Box>
            </Box>
          </DialogContent>
        </StyledDialog>

        {/* Transaction Dialog */}
        <StyledDialog open={transactionOpen} onClose={handleTransactionClose}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButtonStyled onClick={handleTransactionClose} aria-label="Close Transactions">
              <CloseIcon />
            </IconButtonStyled>
          </Box>
          <DialogTitle
            sx={{
              fontFamily: "'Schoolbell', cursive",
              textAlign: 'center',
              borderBottom: `2px solid ${themeConfig.palette.grey[300]}`,
            }}
          >
            Transaction History
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                flexWrap: 'nowrap',
                marginBottom: 2,
              }}
            >
              <FormControl variant="outlined" size="small" sx={{ minWidth: 150, flexShrink: 0 }}>
                <Select
                  value={selectedBetFilter}
                  onChange={(e) => setSelectedBetFilter(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Bet Filter' }}
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: 1,
                  }}
                >
                  <MenuItem value="All">All Bets</MenuItem>
                  {betTitles.slice(1).map((title) => (
                    <MenuItem key={title} value={title}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" size="small" sx={{ minWidth: 150, flexShrink: 0 }}>
                <Select
                  value={selectedStatusFilter}
                  onChange={(e) => setSelectedStatusFilter(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Status Filter' }}
                  sx={{
                    fontFamily: "'Schoolbell', cursive",
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: 1,
                  }}
                >
                  <MenuItem value="All">All Statuses</MenuItem>
                  {statusOptions.slice(1).map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <SecondaryElegantButton
                variant="outlined"
                onClick={() => {
                  setSelectedBetFilter('All');
                  setSelectedStatusFilter('All');
                }}
                sx={{
                  fontFamily: "'Schoolbell', cursive",
                }}
              >
                Clear Filters
              </SecondaryElegantButton>
            </Box>

            <List>
              {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <CircularProgress color="primary" />
                </Box>
              ) : filteredTransactions.length > 0 ? (
                filteredTransactions.map((tx) => (
                  <React.Fragment key={tx.hash}>
                    <TransactionListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <CircularImage
                          src={assetDetails[tx.assets[0].asset]?.image || '/default.png'}
                          alt={tx.assets[0].asset}
                          size={40}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ fontFamily: "'Schoolbell', cursive", fontWeight: 'bold' }}
                            >
                              {tx.betTitle}
                            </Typography>
                            <IconButton
                              onClick={() => handleToggleExpand(tx.hash)}
                              size="small"
                              sx={{ color: themeConfig.palette.primary.main }}
                              aria-label="Expand transaction details"
                            >
                              {expandedTransactions[tx.hash] ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </IconButton>
                          </Box>
                        }
                        secondary={
                          <>
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary' }}
                            >
                              <strong>Time:</strong> {tx.time}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary' }}
                            >
                              <strong>Assets:</strong>
                              <List disablePadding>
                                {tx.assets.map((asset, idx) => (
                                  <ListItem key={idx} disableGutters>
                                    <ListItemAvatar>
                                      <CircularImage
                                        src={assetDetails[asset.asset]?.image || '/default.png'}
                                        alt={asset.asset}
                                        size={24}
                                      />
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={`${asset.amount.toLocaleString(undefined, {
                                        minimumFractionDigits: asset.asset === 'ERMO' ? 0 : 2,
                                        maximumFractionDigits: asset.asset === 'ERMO' ? 0 : 2,
                                      })} ${asset.asset}`}
                                    />
                                  </ListItem>
                                ))}
                              </List>
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary' }}
                            >
                              <strong>Bet Status:</strong> {tx.betStatus}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary' }}
                            >
                              <strong>Result:</strong> {tx.result || 'No result'}
                            </Typography>
                            <Collapse in={expandedTransactions[tx.hash]} timeout="auto" unmountOnExit>
                              <Typography
                                variant="body2"
                                sx={{ fontFamily: "'Schoolbell', cursive", color: 'text.primary', mt: 1 }}
                              >
                                <strong>Transaction Hash:</strong> {tx.hash}
                              </Typography>
                              <Box sx={{ mt: 1 }}>
                                {tx.assets.map((asset, idx) => {
                                  const assetDetail = assetDetails[asset.asset];
                                  if (assetDetail && assetDetail.policyId) {
                                    return (
                                      <Box key={idx} sx={{ mt: 1, pl: 2 }}>
                                        <Typography
                                          variant="body2"
                                          sx={{ fontFamily: "'Schoolbell', cursive", fontWeight: 'bold' }}
                                        >
                                          {asset.asset} Details:
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{ fontFamily: "'Schoolbell', cursive" }}
                                        >
                                          <strong>Policy ID:</strong> {assetDetail.policyId}
                                        </Typography>
                                        {assetDetail.fingerprint && (
                                          <Typography
                                            variant="body2"
                                            sx={{ fontFamily: "'Schoolbell', cursive" }}
                                          >
                                            <strong>Fingerprint:</strong> {assetDetail.fingerprint}
                                          </Typography>
                                        )}
                                        <Typography
                                          variant="body2"
                                          sx={{ fontFamily: "'Schoolbell', cursive" }}
                                        >
                                          <strong>Asset Name:</strong> {assetDetail.name} ({assetDetail.unit.slice(-14)})
                                        </Typography>
                                      </Box>
                                    );
                                  }
                                  return null;
                                })}
                              </Box>
                            </Collapse>
                          </>
                        }
                      />
                    </TransactionListItem>
                    <Divider component="li" />
                  </React.Fragment>
                ))
              ) : (
                <Typography sx={{ fontFamily: "'Schoolbell', cursive", textAlign: 'center', mt: 2 }}>
                  No transactions found.
                </Typography>
              )}
            </List>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 2,
              }}
            >
              <DownloadButton transactions={transactions} />
            </Box>
          </DialogContent>
        </StyledDialog>

        {/* Desktop Popup Notice */}
        <StyledDialog
          open={desktopPopupOpen}
          onClose={closeDesktopPopup}
          aria-labelledby="desktop-popup-title"
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButtonStyled onClick={closeDesktopPopup} aria-label="Close Notice">
              <CloseIcon />
            </IconButtonStyled>
          </Box>
          <DialogTitle
            id="desktop-popup-title"
            sx={{
              fontFamily: "'Schoolbell', cursive",
              textAlign: 'center',
              borderBottom: `2px solid ${themeConfig.palette.grey[300]}`,
            }}
          >
            Notice
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                fontFamily: "'Schoolbell', cursive",
                fontSize: '1.1rem',
                textAlign: 'center',
                marginBottom: 2,
              }}
            >
              Enjoy the full experience on mobile! The desktop version is available, but still a work in progress.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ElegantButton
                onClick={closeDesktopPopup}
                variant="contained"
                sx={{
                  fontFamily: "'Schoolbell', cursive",
                }}
              >
                Got it!
              </ElegantButton>
            </Box>
          </DialogContent>
        </StyledDialog>
      </AppContainer>
    </ThemeProvider>
  );
};

export default BetPage;
