import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Card, Typography, Button, Box, TextField, MenuItem, Select, InputLabel, FormControl, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { motion } from 'framer-motion';

const Container = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: 350,
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  textAlign: 'center',
  boxShadow: '0 8px 12px rgba(0,0,0,0.1)',
  backgroundColor: '#ffffff',
  position: 'relative',
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  fontSize: '1.5rem',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1, 4),
  textTransform: 'none',
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const CoinContainer = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  margin: '0 auto',
  perspective: 1000,
}));

const CoinImage = styled(motion.img)(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  objectFit: 'cover',
  boxShadow: '0 4px 10px rgba(0,0,0,0.15)'
}));

const HEADS_IMAGE = '/ermo.jpg';
const TAILS_IMAGE = '/ermo.png';

export default function CoinFlip() {
  const [outcome, setOutcome] = useState('tails'); // current shown outcome
  const [isFlipping, setIsFlipping] = useState(false);

  const [betChoice, setBetChoice] = useState('heads');
  const [betAmount, setBetAmount] = useState(10);
  const [userBalance, setUserBalance] = useState(100);

  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);

  const flipCoin = () => {
    if (betAmount > userBalance) {
      alert('You do not have enough balance.');
      return;
    }

    setIsFlipping(true);
    // Decide final outcome
    const finalOutcome = Math.random() < 0.5 ? 'heads' : 'tails';

    // Halfway through flip (at 1s), we switch the displayed outcome image
    setTimeout(() => {
      setOutcome(finalOutcome);
    }, 1000);

    // After the full animation (2s), we conclude the bet
    setTimeout(() => {
      setIsFlipping(false);
      if (betChoice === finalOutcome) {
        setUserBalance((prev) => prev + betAmount);
      } else {
        setUserBalance((prev) => prev - betAmount);
      }
    }, 2000);
  };

  const imageSrc = outcome === 'heads' ? HEADS_IMAGE : TAILS_IMAGE;

  const handleCloseDisclaimer = () => {
    setIsDisclaimerOpen(false);
  };

  return (
    <>
      <Dialog open={isDisclaimerOpen} onClose={() => {}} disableEscapeKeyDown>
        <DialogTitle>Coming Soon</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            This feature is currently under development.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDisclaimer} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Container>
        <StyledCard>
          <HeaderTypography variant="h5" color="text.primary">
            Flip the Coin
          </HeaderTypography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            Choose your side, place your bet, and flip!
          </Typography>

          {/* Betting Interface */}
          <Box sx={{ textAlign: 'left', mb: 2 }}>
            <Typography variant="subtitle1">
              <strong>Balance:</strong> {userBalance} credits
            </Typography>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel>Choose Side</InputLabel>
              <Select
                value={betChoice}
                label="Choose Side"
                onChange={(e) => setBetChoice(e.target.value)}
              >
                <MenuItem value="heads">Heads</MenuItem>
                <MenuItem value="tails">Tails</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Bet Amount"
              type="number"
              value={betAmount}
              onChange={(e) => setBetAmount(Number(e.target.value))}
              sx={{ mt: 2 }}
            />
          </Box>

          <CoinContainer>
            <CoinImage
              src={imageSrc}
              alt={outcome}
              animate={isFlipping ? { rotateY: [0, 180, 360] } : { rotateY: 0 }}
              transition={{ duration: 2, ease: 'easeInOut' }}
              style={{ backfaceVisibility: 'hidden' }}
            />
          </CoinContainer>

          <StyledButton onClick={flipCoin} disabled={isFlipping || isDisclaimerOpen}>
            {isFlipping ? 'Flipping...' : 'Flip Coin'}
          </StyledButton>

          <Typography variant="h6" sx={{ marginTop: 2, fontWeight: 'bold', minHeight: '1.5em' }}>
            {!isFlipping ? `Outcome: ${outcome.charAt(0).toUpperCase() + outcome.slice(1)}` : 'Outcome: ...'}
          </Typography>

          {!isFlipping && (
            <Typography variant="body1" sx={{ mt: 1 }}>
              {betChoice === outcome
                ? `You Won ${betAmount} credits!`
                : `You Lost ${betAmount} credits.`}
            </Typography>
          )}
        </StyledCard>
      </Container>
    </>
  );
}
