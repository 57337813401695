// src/pages/Home.js

import React from 'react';
import { Grid, CardActionArea, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { BetCard, CardContainer, HeaderTypography } from './StyledComponents'; // Ensure the path is correct

// ---------------------- Styled Components ----------------------

// Center the container with padding and max-width
const CenteredCardContainer = styled(CardContainer)(({ theme }) => ({
  padding: theme.spacing(5, 2),
  maxWidth: '1200px',
  margin: 'auto',
}));

// ---------------------- Home Component ----------------------

const Home = () => {
  const features = [
    {
      name: 'Bet',
      description: 'Place your bets and win big!',
      path: '/bet',
    },
    {
      name: 'Lottery',
      description: 'Participate in exciting lotteries.',
      path: '/lottery',
    },
    {
      name: 'Roulette',
      description: 'Spin the roulette and test your luck!',
      path: '/roulette',
    },
    {
      name: 'CoinFlip',
      description: 'Flip a coin, place a bet, and double or nothing!',
      path: '/coinflip',
    },
    {
      name: 'Dice',
      description: 'Roll the dice and multiply your winnings!',
      path: '/dice',
    },
  ];

  return (
    <CenteredCardContainer>
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature) => (
          <Grid item xs={12} sm={6} md={4} key={feature.name}>
            <Box display="flex" justifyContent="center">
              <CardActionArea
                component={Link}
                to={feature.path}
                aria-label={`Navigate to ${feature.name}`}
                sx={{
                  borderRadius: '12px',
                  boxShadow: 3,
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  padding: { xs: 2, sm: 3 },
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                  width: '100%',
                  maxWidth: '300px',
                }}
              >
                <BetCard
                  sx={{
                    textAlign: 'center',
                    padding: { xs: 2, sm: 3 },
                  }}
                >
                  <HeaderTypography
                    variant="h6"
                    sx={{
                      fontSize: { xs: '1rem', md: '1.2rem' },
                      fontWeight: 700,
                      marginBottom: 1,
                    }}
                  >
                    {feature.name}
                  </HeaderTypography>

                  <Box
                    height="2px"
                    bgcolor="#000"
                    margin="8px 0"
                    width="80%"
                    mx="auto"
                  ></Box>

                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "'Schoolbell', cursive",
                      color: '#555',
                      fontSize: { xs: '0.85rem', sm: '1rem' },
                      padding: { xs: '0 12px', sm: '0 16px' },
                    }}
                  >
                    {feature.description}
                  </Typography>
                </BetCard>
              </CardActionArea>
            </Box>
          </Grid>
        ))}
      </Grid>
    </CenteredCardContainer>
  );
};

export default React.memo(Home);
